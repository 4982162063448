import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export function WorkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} >
       <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
           <rect x="2" y="6" width="16" height="12" rx="2" stroke="#8709EB" strokeWidth="2"/>
           <path d="M14 6V5C14 3.34315 12.6569 2 11 2H9C7.34315 2 6 3.34315 6 5V6" stroke="#8709EB" strokeWidth="2"/>
       </svg>
    </SvgIcon>
  );
}

export function LocationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} >
       <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M17.2433 7.17098L17.2424 7.18925V7.20753C17.2424 12.0809 13.7785 15.0965 11.4214 16.598C10.8486 16.9628 10.1267 16.9648 9.55849 16.6041C7.20932 15.1128 3.75754 12.0796 3.75754 7.20753V7.18924L3.75665 7.17097C3.67594 5.51731 4.33326 3.89079 5.59855 2.67053C6.86767 1.45081 8.63838 0.75 10.5011 0.75C12.3637 0.75 14.1324 1.45069 15.4017 2.67073C16.6684 3.89019 17.324 5.51648 17.2433 7.17098Z" stroke="#8709EB" strokeWidth="1.5"/>
           <line x1="3.75" y1="19.25" x2="17.25" y2="19.25" stroke="#8709EB" strokeWidth="1.5" strokeLinecap="round"/>
           <circle cx="10.5" cy="7.5" r="1.5" fill="#8709EB"/>
       </svg>
    </SvgIcon>
  );
}
