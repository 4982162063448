import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import JobSearchItemsWide from './JobSearchItemsWide'
import logo from '../logo_positive.svg'


export default function JobSearch(props){
    const {searchItemsProps} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up(800));
    const SearchItemsDesktop = fullScreen ? <JobSearchItemsWide {...searchItemsProps} /> : null;

    const logoSx = { maxWidth: {w800: '50%', xs: '98%'}, width: {w800: '425px', xs: '300px' }, height: {w800: 'auto', xs: '3em'}};
    const boxSx = {maxWidth: 960, marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto', textAlign: {w800: 'left', xs: 'center'}, paddingTop: {w800: '120px', xs: '30px'} ,width: {w800: '95%', xs: '100%'},}
    const typography1Sx = {paddingTop: {w800: '1em', xs: '1em'}, fontSize: {w800: '32px', xs:'20px'}, fontWeight: {w800: 300, xs: 400 }, letterSpacing: '-0.02em' };
    const title2BoxSx = {margin: {w800: '0px auto',  xs:  '0px 20px'} }
    const typography2Sx = { paddingTop: '10px', paddingBottom: '0.5em', fontWeight: 700, fontSize: {w800: '42px !important', xs: '28px'}, letterSpacing: '-0.03em',  }; //inlineSize: {w500: 'none', xs: '250px'}, overflowWrap: {w500: 'none', xs: 'break-wrod'}

    return (
        <Box sx={boxSx}>
            <Box component="img" src={logo} sx={{ ...logoSx}} alt="logo"/>
            <Typography variant="h7" component="div" sx={typography1Sx}>
                Out of 440K+ jobs on our site
            </Typography>
            <Box sx={title2BoxSx}>
                <Typography variant="h5" component="div" sx={typography2Sx}>
                    {/*Find the <Box component="span" sx={{color: 'job.main'}}>best jobs</Box><Box component="span" sx={{ color: 'location.main' }}> near me </Box>now:*/}
                    Find the best jobs near me now:
                </Typography>
            </Box>

            {SearchItemsDesktop}

        </Box>
    );
}
