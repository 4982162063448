import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import FullBottomScreenDialog from './FullBottomScreenDialog'
import SearchItemsDialogContent from './SearchItemsDialogContent'
import SearchIcon from '@mui/icons-material/Search';
import { BUTTON_TEXT_NARROW_SCREEN } from '../Cms.js'
import useWindowDimensions from '../Hooks/useWindowDimensions.js'


export default function SearchItemsMobileDevice(props){
    const {jobProps, locationProps, onClick, mobileOpenDialog } = props;
    const [open, setOpen] = useState(false);
    const { innerHeight } = useWindowDimensions();

    const maxHeight = 400;
    let heightPercentage = 100;
    if (innerHeight > maxHeight){
        heightPercentage = (maxHeight / innerHeight) * 100;
    }

    const openDialog = () => {
        setOpen(true);
        mobileOpenDialog();
    }

    const closeDialog = () => {
        setOpen(false);
    };

    const onButtonClick = () => {
        onClick();
        closeDialog();
    }
    const dialogContent = <SearchItemsDialogContent jobProps={jobProps} locationProps={locationProps} onButtonClick={onButtonClick} />;

    const buttonProps = {
        onClick: openDialog,
        variant: "contained",
        color: "appBarButton",
        size: "small",
        sx: {whiteSpace: 'nowrap', width: '112px', height: '40px', fontSize: '14px', fontWeight: 700, marginTop: '1em', marginBottom: '1em', marginLeft: '1em', paddingLeft: '12px', lineHeight: 2, color: 'appBarButton.textColor', boxShadow: 'none', borderRadius: '12px'},
    }

    return (
        <Box sx={{width: '100%', display:"flex", flexDirection: "rowReverse", alignItems: "center"}}>
          <Button {...buttonProps} endIcon={<SearchIcon sx={{width:"24px", height: "24px"}} />} >{BUTTON_TEXT_NARROW_SCREEN}</Button>
          <FullBottomScreenDialog
            open={open}
            closeDialog={closeDialog}
            heightPercentage={heightPercentage}
            contentElement={dialogContent}
          />
        </Box>
      );
}