import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';


const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "#fff",
  background: "rgba(255, 255, 255, 0.15)",
  borderRadius: "4px",
  [theme.breakpoints.up("w800")]: {
    borderRadius: "8px",
  },
  width: "100%",
  "& .MuiInputBase-root": {
      borderRadius: "4px",
      [theme.breakpoints.up("w800")]: {
        borderRadius: "8px",
      },
  },
  "& .MuiOutlinedInput-input": {
      height: '17px',
  },
  "& fieldset": {
    borderWidth: "0px",
    "& fieldset:hover, & fieldset:focus, & fieldset:active": {
      borderWidth: "0px"
    },
    "& .MuiInputBase-input": {
      padding: theme.spacing(2, 1, 1, 2),
      transition: theme.transitions.create("width"),
      color: "#fff",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch"
        }
      }
    }
  }
}));


export default function SearchAutocomplete(props) {
    const {label, color, options, icon, value, setValue, inputValue, setInputValue, inputSx} = props;
    //const sx = {input: { color: color }}
    let {labelColor, labelFontWeight, labelFontSize, noOptionsText} = props;
    labelColor = labelColor || "#FFFFFF";
    labelFontWeight = labelFontWeight || 700;
    labelFontSize = labelFontSize || '1.5rem';
    noOptionsText = noOptionsText || "No options";


  return (
    <Box sx={{textAlign: 'left'}} >
      <FormLabel sx={{color: labelColor, fontWeight: labelFontWeight, fontSize: labelFontSize }} >{label}</FormLabel>
      <Autocomplete
        value={value}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        freeSolo
        noOptionsText={noOptionsText}
        blurOnSelect={true}
        renderInput={
            (params) =>
                <StyledTextField
                    {...params}
                    label={null}
                    color={color}
                    focused
                    sx={inputSx}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            {icon}
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      )
                    }}

                />
        }
      />
    </Box>
  );
}