// Globals
export const JOB_SEARCH_LABEL = "Job";
export const LOCATION_SEARCH_LABEL = "Where";
export const JOB_EMPTY_AUTOCOMPLETE_TEXT = "Type job here";
export const LOCATION_EMPTY_AUTOCOMPLETE_TEXT = "Type location here";

// AppBar
export const APPBAR_TITLE = "Anoma Job Search";
export const BUTTON_TEXT_WIDE_SCREEN = "Find Jobs";
export const BUTTON_TEXT_NARROW_SCREEN = BUTTON_TEXT_WIDE_SCREEN;
export const BUTTON_SEARCH_DIALOG = "SEARCH";
export const ADDITIONAL_DETAILS = "More details";

// Landing Page
export const PARTNERS_TITLE = "Trusted by our Partners";

// SearchPage
export const POST_TYPE_CTA_MAIN = 'CTA_MAIN';
export const POST_TYPE_JEWEL1 = 'JEWEL1';
export const POST_TYPE_JEWEL2 =  'JEWEL2';
export const POST_TYPE_JEWEL3 =  'JEWEL3';

export const CTA_MAIN_TEXT = 'Go to Position';
export const JEWEL1_TEXT = 'JEWEL1';
export const JEWEL2_TEXT =  'JEWEL2';
export const JEWEL3_TEXT =  'JEWEL3';


// Jewel mapping
export const TAG_TO_TEXT = {
    'part_time': "Part Time",
    'full_time': "Full Time",
    'hybrid_work': "Hybrid",
    'full_wfh': "Full WFH",
}

// Search Dialog
export const SEARCH_DIALOG_TITLE = 'Find the Best Job Here Now:';

// Footer
export const RIGHTS_TEXT = "JobHereNow, Inc. © 2023 All Rights Reserved Worldwide";

// Keys
export const PRIVACY_POLICY_KEY = "0ddd86ea-44ed-46cc-9b14-87b728b0abb3";
export const TERMS_KEY = "b8658783-c7c4-43d0-83f3-c1749be189ae";
export const COOKIES_KEY = "9b77465e-fb31-40e7-8b29-51dbe7f6afd6";

