import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FooterGrid from './FooterGrid';
import FooterLinksMobile from './FooterLinksMobile'
import FooterLinksDesktop from './FooterLinksDesktop'
import logo from '../logo_negative.svg'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCurrentPage } from '../Hooks/useCurrentPage'
import { footerLinkClickEvent } from '../Shared/Events'


const links = ['Jobs', 'No Experience Jobs', 'Start Now Jobs', 'High Salary Jobs'];
//const filters = ['About us', 'Cookies', 'Privacy Policy', 'Terms and Conditions', RIGHTS_TEXT];


export default function Footer(props){
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('lg'));
    const page = useCurrentPage();
    const footerLinks = isSmUp ? <FooterLinksDesktop page={page} /> : <FooterLinksMobile page={page} /> ;

    return (
    <Box sx={{ marginTop: 'auto', backgroundColor: "primary.main", pt: '2em'}}>
        <Box sx={{ margin: 'auto', maxWidth: 1050, pt: {md: '40px'}, pb: {md: '60px'} }}>
          <Grid container spacing={{ md: 1, sm: 6, xs: 6 }} alignItems="stretch" justifyContent="center" direction="row" >
            <Grid item xl={3} lg={3} md={3.5} sm={12} xs={12} sx={{ paddingLeft: '0px', textAlign: {md: 'left', sx: 'center'}, marginLeft: '0px' }} >
                <Link to="/" onClick={() => footerLinkClickEvent('logo', page) } >
                  <Box component="img" src={logo} sx={{ width: "169px" }} alt="logo"/>
                </Link>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={12} xs={12} >
              <Box >
                <FooterGrid items={links}/>
              </Box>
            </Grid>

            <Grid item xl={5} lg={5} md={4.5} sm={12} xs={12} >
                {footerLinks}
            </Grid>
          </Grid>
        </Box>
    </Box>
    );
}
