import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import "@fontsource/inter";

// Media query example: https://mui.com/material-ui/customization/typography/#responsive-font-sizes

const breakpoints =  {
    values: {
      xs: 0,
      TinyMobile: 250,
      smallMobile: 350,
      w400: 400,
      w500: 500,
      sm: 600,
      w800: 800,
      md: 900,
      lg: 1200,
      w1300: 1300,
      xl: 1536,
    },
};

export let AppTheme = createTheme({
  breakpoints: breakpoints,
  palette: {
    appBackground: {
        main: '#EFEFEF',
    },
    landingBackground: {
        main: '#FFFFFF',
    },
    primary: {
          light: '#9f3aef',
          main: '#8709EB',
          dark: '#5e06a4',
          contrastText: '#fff',
    },
    appBarButton: {
        light: '#9740e2',
        main: '#6200AF',
        dark: '#2a007e',
        textColor: '#FFFFFF'
    },
    job: {
      main: red[500],
    },
    location: {
      main: 'blueviolet',
    },
    now: {
        main: 'darkolivegreen',
    },
    searchTextField: {
        main: '#FFFFFF',
    },
    partners: {
        main: '#FFFFFF',
    },
    jobPostLocation: {
        main: '#888888',
    },
    dialogBoxTextFieldBox: {
        main: '#888888',
    },
    dialogBoxLabel: {
        main: '#888888',
    },
  },
  // Setting Font: https://medium.com/@weberzt/importing-a-google-font-into-your-react-app-using-material-ui-773760ded532
  typography: {
      fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
      //fontFamily: "Inter",
      button: {
        textTransform: 'none'
      }
    }
});

AppTheme = responsiveFontSizes(AppTheme);

AppTheme.components = {
  MuiToolbar: {
      styleOverrides: {
          root: {
              height: 80,
              minHeight: 80,
          },
      }
  },
  MuiAvatar: {
    styleOverrides: {
          root: {
            border: '0.1px solid lightgray',
            height: 72,
            width: 72,
            [AppTheme.breakpoints.down('md')]: {
              height: 48,
              width: 48,

              },
          },
      }
    }
};