import Box from '@mui/material/Box';
import ContentPage from './ContentPage';
import Typography from '@mui/material/Typography';


export default function About(){
    const paragraphSx = { marginTop: '1em', fontSize: '1rem', color: '#111', lineHeight: '150%' }
    const titleText = "Welcome to Job Here Now.com!";
    const content = (
        <Box>
            <Typography fontWeight="400" component="div" sx={paragraphSx}>
             <p> Welcome to JobHereNow.com! We understand that finding a new job can be a stressful and overwhelming process. </p>
             <p>  Finding an honest paying, reliable source of income that matches your skills can be a challenge, especially in today's job market. That’s where we come in.  </p>
             <p>Our team is dedicated to providing you a range of job options that can help you earn money and gain valuable experience. With AI-based personalized job recommendations, our mission is to help you land the position that is the best fit for your needs.  </p>
             <p> Let us help you take the stress out of your job search, and find the perfect fit for your life goals. </p>

            </Typography>
        </Box>);

    return <ContentPage titleText={titleText} content={content}/>
}