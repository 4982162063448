import * as React from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


const StyledTextField = styled(TextField)(({ theme }) => ({
  background: "#fff",
  borderRadius: "4px",
  width: "100%",
  "& fieldset": {
    borderWidth: "0px",
    "& fieldset:hover, & fieldset:focus, & fieldset:active": {
      borderWidth: "0px"
    },
    "& .MuiInputBase-input": {
      padding: theme.spacing(2, 1, 1, 2),
      transition: theme.transitions.create("width"),
      color: "#fff",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch"
        }
      }
    }
  }
}));



export default function AppBarAutocomplete(props) {
  const {label, options, icon, value, setValue, inputValue, setInputValue} = props;

  return (
      <Autocomplete
          value={value}
          onChange={(event: any, newValue: string | null) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disablePortal
          freeSolo
          options={options}
          sx={{ marginLeft: 2, width: { xs: "100%", sm: "20ch", lg: "28ch" }, borderWidth: "0px" }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              placeholder={label}
              //size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    {icon}
                  </InputAdornment>
                )
              }}
            />
          )}
        />
  );
}
