import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import BasePage from '../Shared/BasePage';
import Typography from '@mui/material/Typography';


export default function ContentPage(props){
    const {titleText, content} = props;
    const TitleSx = { fontSize: '24px', color: '#111', lineHeight: '150%' }

    const theme = useTheme();
    const matchesSmallUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    
    let boxWidth = '95%';
    if (matchesLgUp){
        boxWidth = '80%'
    } else if (matchesSmallUp){
        boxWidth = '90%'
    }
    
    const pageFullContent = (
        <Box sx={{minHeight: '20vh', margin: '4em auto', width: boxWidth, maxWidth: '800px', textAlign: 'left'}}>
           <Typography fontWeight="700" component="div" sx={TitleSx}>
            {titleText}
            </Typography>
            {content}
        </Box>
    );

    return <BasePage content={pageFullContent}/>;
}
