import { useTheme } from '@mui/material/styles';
import { alpha } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SearchAutocompleteAsync from '../Shared/SearchAutocompleteAsync';
import SearchIcon from '@mui/icons-material/Search';
import { BUTTON_TEXT_WIDE_SCREEN } from '../Cms.js';


export default function JobSearchItemsNarrow(props){
    const {jobProps, locationProps, buttonProps} = props;
    const theme = useTheme();

    const internalBoxWrapperSx = {margin: {w400: '0px auto',  xs:  '0px 20px'}, paddingTop: '5px' };
    const backgroundBoxSx = { backgroundColor: alpha(theme.palette.primary.main, 0.9), position: 'relative', marginTop: '-375px', paddingTop: '1em' }
    const internalBoxSx = {textAlign: 'center', justifyItems: "center",alignContent: 'space-between', display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto'};
    const inputSx = {width: '100%', backgroundColor: '#FFF', border: '1px solid #AAAAAA', marginTop: '8px' };
    const autocompleteProps = {labelFontSize: '16px'};

    return (
        <Box sx={backgroundBoxSx}  >
            <Box sx={internalBoxWrapperSx}>
                <Box sx={internalBoxSx}>
                    <Grid container spacing={{xs: 3}} justifyContent="center">
                        <Grid item xs={12} >
                            <SearchAutocompleteAsync  {...jobProps} {...autocompleteProps} inputSx={inputSx} />
                        </Grid>

                        <Grid item xs={12} >
                            <SearchAutocompleteAsync {...locationProps} {...autocompleteProps} inputSx={inputSx} />
                        </Grid>

                        <Grid item xs={12} >
                            <Button {...buttonProps} endIcon={<SearchIcon /> } >{BUTTON_TEXT_WIDE_SCREEN}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
