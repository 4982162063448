import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from '../logo_negative.svg'
import SearchItemsWideDevice from './SearchItemsWideDevice'
import SearchItemsMobileDevice from './SearchItemsMobileDevice'
import { WorkIcon, LocationIcon } from './CustomIcons'
import { JOB_SEARCH_LABEL, LOCATION_SEARCH_LABEL} from '../Cms.js'
import { APP_BAR_TYPE, LANDING_PAGE_TYPE } from '../Shared/SearchAutocompleteAsync';
import { JOB_EMPTY_AUTOCOMPLETE_TEXT, LOCATION_EMPTY_AUTOCOMPLETE_TEXT } from '../Cms.js'
import { jobValueState, locationValueState, getJobsUrl, suggestJobs, suggestLocations, getLocationCords } from '../GlobalState'
import { useGlobalState } from 'state-pool';
import { useCurrentPage } from '../Hooks/useCurrentPage'
import { searchClickEvent, SearchBarLogoClickEvent, openMobileDialogEvent } from '../Shared/Events'


export default function SearchAppBar(props) {
  const navigate = useNavigate();
  const page = useCurrentPage();
  let { jobQuery, locationQuery, labelQuery } = props;
  jobQuery = jobQuery || "";
  locationQuery = locationQuery || "";
  labelQuery = labelQuery || "";

  // Jobs autocomplete vars
  const [jobValue, jobSetValue] = useGlobalState(jobValueState);
  const [jobInputValue, jobSetInputValue] = useState(jobQuery);
  const [jobOptions, jobSetOptions] = useState([]);

  // Locations autocomplete vars
  const [locationValue, locationSetValue] = useGlobalState(locationValueState);
  const [locationInputValue, locationSetInputValue] = useState(locationQuery);
  const [locationOptions, locationSetOptions] = useState([]);

  const theme = useTheme();
  const matchesXl = useMediaQuery(theme.breakpoints.up('md'));
  const logoSx = {maxWidth: 'calc(100% - 160px)'}
  // TODO: check why/if this is rendered multiple times

  const autoCompleteType = matchesXl ? APP_BAR_TYPE : LANDING_PAGE_TYPE;

  const jobProps = {
      options: jobOptions,
      setOptions: jobSetOptions,
      noOptionsText: JOB_EMPTY_AUTOCOMPLETE_TEXT,
      fetchItems: suggestJobs,
      autoCompleteType: autoCompleteType,
      color: "dialogBoxTextFieldBox",
      labelColor: "dialogBoxTextFieldBox",
      label: JOB_SEARCH_LABEL,
      icon: <WorkIcon color="primary" style={{ marginLeft: "8px" }} />,
      value: jobValue,
      setValue: jobSetValue,
      inputValue: jobInputValue,
      setInputValue: jobSetInputValue,
  }

  const locationProps = {
      options: locationOptions,
      setOptions: locationSetOptions,
      noOptionsText: LOCATION_EMPTY_AUTOCOMPLETE_TEXT,
      fetchItems: suggestLocations,
      autoCompleteType: autoCompleteType,
      color: "dialogBoxTextFieldBox",
      label: LOCATION_SEARCH_LABEL,
      labelColor: "dialogBoxTextFieldBox",
      icon: <LocationIcon color="primary" style={{ marginLeft: "8px" }} />,
      value: locationValue,
      setValue: locationSetValue,
      inputValue: locationInputValue,
      setInputValue: locationSetInputValue,
  }

  const getClickContext = () => {
    const jobQuery = jobInputValue || null;
    const label = labelQuery || null;
    const url = getJobsUrl(jobQuery, locationValue, label);
    const location = getLocationCords(locationValue);
    return {jobQuery, url, location}
  }

  const searchOnClick = (event) => {
      const {jobQuery, url, location} = getClickContext();
      const metadata = JSON.stringify({new_url: url, info: "'new_url' is the new url this events navigates to"})
      const SEARCH_EVENT_TYPE = 'search_click';
      const eventData = {
          name: 'appbar_search_click',
          type: 'search_click',
          tag: 'search_click',
          string_value: jobQuery,
          latitude: location.lat,
          longitude: location.long,
          metadata: metadata,
          page_type: page,
      }
      searchClickEvent(eventData)
      return navigate(url)
  }

  const mobileOpenDialog = () => {
    const {jobQuery, url, location} = getClickContext();
    const MOBILE_SEARCH_DIALOG_OPEN = 'open_mobile_search_dialog';
    const eventData = {
      name: "appbar_open_mobile_search_dialog",
      type: MOBILE_SEARCH_DIALOG_OPEN,
      tag: MOBILE_SEARCH_DIALOG_OPEN,
      string_value: "appbar",
      latitude: location.lat,
      longitude: location.long,
      page_type: page,
    }
    openMobileDialogEvent(eventData)
  }

  const logoOnClick = () => {
      const {jobQuery, location} = getClickContext();
      const LOGO_CLICK_EVENT_TYPE = 'logo_click';
      const eventData = {
        name: LOGO_CLICK_EVENT_TYPE,
        type: LOGO_CLICK_EVENT_TYPE,
        tag: LOGO_CLICK_EVENT_TYPE,
        string_value: jobQuery,
        latitude: location.lat,
        longitude: location.long,
        page_type: page,
      }
      SearchBarLogoClickEvent(eventData)
  }


  const searchItem = matchesXl
      ? <SearchItemsWideDevice jobProps={jobProps} locationProps={locationProps} onClick={searchOnClick} />
      : <SearchItemsMobileDevice jobProps={jobProps} locationProps={locationProps} onClick={searchOnClick} mobileOpenDialog={mobileOpenDialog} />

  return (
      <AppBar position="sticky">
        <Toolbar>
          <Link to="/" onClick={logoOnClick}>
            <Box component="img" src={logo} sx={{ ...logoSx, height: '3em', position: 'absolute', top: '20px'}} alt="logo"/>
          </Link>

          <Box sx={{ marginLeft: 'auto', marginRight: {w1300: 'auto', xs: '0px'} }}>
            {searchItem}
          </Box>
        </Toolbar>
      </AppBar>

  );
}
