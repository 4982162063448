import { submitEvent } from '../ServerApi'


const verbose = false;


export const jobClickEvent = async(name, eventData) => {
    eventData.string_value = name;
    if (verbose){
        console.log(`jobClickEvent event of button '${name}' on page '${eventData['page']}'`);
        console.log(eventData);
    }
    submitEvent(eventData)//.then(console.log("then..."))
}

export const openMobileDialogEvent = async(eventData) => {
    if (verbose){
      console.log(`openMobileDialogEvent for page '${eventData['page_type']}'`);
    }
    submitEvent(eventData);
}


export const showMoreClickEvent = async(eventData) => {
  eventData.name = 'show_more_click';
  eventData.type = 'show_more_click';
  eventData.tag = 'show_more_click';
  if (verbose){
    console.log(`ShowMore clicked for page '${eventData['page_type']}' with current number of posts:`);
  }
  submitEvent(eventData);
}


export const searchClickEvent = async(eventData) => {
    if (verbose){
        console.log(`searchClickEvent event, moving to url '${eventData.metadata}' from page '${eventData.page_type}'`);
        console.log(eventData);
    }
    submitEvent(eventData);
}


export const SearchBarLogoClickEvent = async(eventData) => {
    if (verbose){
        console.log(`SearchBar logo was clicked in page '${eventData.page_type}'`);
    }
    submitEvent(eventData);
}


export const footerLinkClickEvent = async(name, page) => {
    if (verbose){
        console.log(`Footer link '${name}' clicked in page '${page}'`);
    }
    const FOOTER_LINK_CLICK_EVENT = "footer_link_clicked"
    const eventData = {
      name: FOOTER_LINK_CLICK_EVENT,
      type: FOOTER_LINK_CLICK_EVENT,
      tag: FOOTER_LINK_CLICK_EVENT,
      string_value: name,
      page_type: page,
    }
    submitEvent(eventData);
}


export const autoCompleteSelectEvent = async(name, value, page) => {
    console.log(`AutoComplete selected new value='${value}' in page '${page}'`);
}
