import {POST_TYPE_JEWEL1, POST_TYPE_JEWEL2, POST_TYPE_JEWEL3} from './Cms';


const jobs = [
    {id: 0, label: 'Tractor Driver'},
    {id: 1, label: 'Tractor Driver Manager'},
    {id: 2, label: 'Construction site worker'},
    {id: 3, label: 'Construction site Manager'},
    {id: 4, label: 'Data Scientist'},
    {id: 5, label: 'Software Engineer (Frontend)'},
    {id: 6, label: 'Software Engineer (Backend)'},
    {id: 7, label: 'VP R&D'},
    {id: 8, label: 'Product Manager'},
];
//const DEFAULT_JOBS = jobs.slice(0, 4);


const locations = [
     {'id': 0, 'label': 'Menlo Park, California, United States', 'long': -122.177993, 'lat': 37.451967, 'country': 'United States', 'city': 'Menlo Park'},
     {'id': 1, 'label': 'New York, New York, United States', 'long': -73.9866, 'lat': 40.7306, 'country': 'United States', 'city': 'NYC'},
     {'id': 2, 'label': 'Seattle, Washington, United States', 'long': -122.330062, 'lat': 47.603832, 'country': 'United States', 'city': 'Seattle'},
     {'id': 3, 'label': 'London, Greater London, England, United Kingdom', 'long': -0.12764739999999997, 'lat': 51.507321899999994, 'country': 'United Kingdom', 'city': 'London'},
     {'id': 4, 'label': 'Manchester, Greater Manchester, England, United Kingdom', 'long': -2.2451148, 'lat': 53.47948920000002, 'country': 'United Kingdom', 'city': 'Manchester'},
     {'id': 5, 'label': 'Paris, France', 'long': 2.3483915, 'lat': 48.8534951, 'country': 'France', 'city': 'Paris'},
     {'id': 6, 'label': 'Marseille, Bouches-du-Rhône, France', 'long': 5.369952, 'lat': 43.296174, 'country': 'France', 'city': 'Marseille'},
     {'id': 7, 'label': 'Lyon, Rhône, France', 'long': 4.832011, 'lat': 45.757814, 'country': 'France', 'city': 'Lyon'},
     {'id': 8, 'label': 'Toulouse, Haute-Garonne, France', 'long': 1.444247, 'lat': 43.604462, 'country': 'France', 'city': 'Toulouse'},
     {'id': 9, 'label': 'Grenoble, Isère, France', 'long': 5.735782, 'lat': 45.18756, 'country': 'France', 'city': 'Grenoble'},
     {'id': 10, 'label': 'Strasbourg, Bas-Rhin, France', 'long': 7.7468476, 'lat': 48.5834833, 'country': 'France', 'city': 'Strasbourg'},
     {'id': 11, 'label': 'San Diego, California, United States', 'long': -117.162773, 'lat': 32.71742, 'country': 'United States', 'city': 'San Diego'},
     {'id': 12, 'label': 'Los Angeles, California, United States', 'long': -118.242766, 'lat': 34.053691, 'country': 'United States', 'city': 'Los Angeles'},
     {'id': 13, 'label': 'Dallas, Texas, United States', 'long': -96.796856, 'lat': 32.776272, 'country': 'United States', 'city': 'Dallas'},
     {'id': 14, 'label': 'Berlin, Germany', 'long': 13.3888599, 'lat': 52.5170365, 'country': 'Germany', 'city': 'Berlin'},
     {'id': 15, 'label': 'Hamburg, Germany', 'long': 10.000654, 'lat': 53.550341, 'country': 'Germany', 'city': 'Hamburg'},
     {'id': 16, 'label': 'München, Bavaria, Germany', 'long': 11.575382, 'lat': 48.137108, 'country': 'Germany', 'city': 'Munich'},
     {'id': 17, 'label': 'Dresden, Saxony, Germany', 'long': 13.738144, 'lat': 51.049329, 'country': 'Germany', 'city': 'Dresden'},
]
//const DEFAULT_LOCATIONS = locations.slice(0, 4);

const jobDescriptions = [
    'Tractor drivers are employed in the agricultural industry to drive tractors. Their daily duties vary depending on the time of year and type of farm, but they typically transport materials, plow fields, plant seeds, harvest crops, apply fertilizers, and perform tractor maintenance. Tractor drivers are employed in the agricultural industry to drive tractors. Their daily duties vary depending on the time of year and type of farm, but they typically transport materials, plow fields, plant seeds, harvest crops, apply fertilizers, and perform tractor maintenance.',
    'Cool Position. You have to join! Now!',
    'typically transport materials, plow fields, plant seeds, harvest crops, apply fertilizers, and perform tractor maintenance.',
    'You have to join!',
];
const postTypes = [POST_TYPE_JEWEL1, POST_TYPE_JEWEL2, POST_TYPE_JEWEL3];


function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

async function suggestJobs(query){
    console.log(`Mock suggestJobs called with ${query}`);
    await delay(200);
    return jobs.filter(item => item.label.toLowerCase().includes(query.toLocaleLowerCase()));
}

async function suggestLocations(query){
    console.log(`Mock suggestLocations called with ${query}`);
    await delay(200);
    return locations.filter(item => item.label.toLowerCase().includes(query.toLocaleLowerCase()));
}

async function getPosts(title, location){
  console.log(`Mock getPosts called with title="${title}" and location="${location}"`);
  const numPosts = 30;
  const indexes = [...Array(numPosts).keys()];
  const jobPosts = indexes.map(index => (
    {
        title: title,
        location: location.label,
        description: jobDescriptions[index % jobDescriptions.length],
        postType: postTypes[index % postTypes.length],
        organization: `Comp${index % 10}`,
    }));

  return jobPosts;
}

export { getPosts, suggestJobs, suggestLocations};
