import { useLoaderData, redirect } from "react-router-dom";
import BasePage from '../Shared/BasePage';
import JobPostList from './JobPostList';
import { getChartPosts } from '../ServerApi'
import { useCurrentPage } from '../Hooks/useCurrentPage'


export async function loader({ params }) {
  let { adGroupId } = params;
  adGroupId = adGroupId || "";

  const {jobPosts, chartId} = await getChartPosts(adGroupId);
  if (!jobPosts || jobPosts.length === 0){
      // TODO: log this as events
      return redirect("/jobs/");
  }
  return { adGroupId, jobPosts, chartId };
}


export default function ChartPage(props) {
    const {adGroupId, jobPosts, chartId } = useLoaderData();
    const page = useCurrentPage();
    // For now, charts do not support pagination
    const pageNum = 1;
    const jobListParams = { jobPosts, chartId, page, pageNum, showLoadMore: false };
    return <BasePage content={<JobPostList {...jobListParams} />}/>;
}
