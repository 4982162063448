import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { RIGHTS_TEXT } from '../Cms';
import NoStyleLink from './NoStyleLink'
import { footerLinkClickEvent } from '../Shared/Events'


export default function FooterLinksDesktop(props){
    const {page} = props;

    return (
        <Grid container spacing={1} justifyContent='center' sx={{mb: '2em', textAlign: "left" }} >

           <Grid item xs={12} sx={{color: 'white'}}>
              <Box>
                  <Box component="span" sx={{pr: '1em'}}>
                    <NoStyleLink to="/about" onClick={()=>footerLinkClickEvent('about', page)} >About us</NoStyleLink>
                  </Box>
                  <Box component="span" sx={{pr: '1em'}}>
                    <NoStyleLink to="/cookies" onClick={()=>footerLinkClickEvent('cookies', page)} >Cookies</NoStyleLink>
                  </Box>
                  <Box component="span" sx={{pr: '1em'}}>
                    <NoStyleLink to="/privacypolicy" onClick={()=>footerLinkClickEvent('privacypolicy', page)} >Privacy Policy</NoStyleLink>
                  </Box>
                  <Box component="span">
                    <NoStyleLink to="/termsandconditions" onClick={()=>footerLinkClickEvent('termsandconditions', page)} >Terms And Conditions</NoStyleLink>
                  </Box>
              </Box>
          </Grid>

            <Grid item xs={12} >
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={12} >
                        <Box sx={{color: 'white', mt: '1em'}}>{RIGHTS_TEXT}</Box>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}
