import { useRouteError } from "react-router-dom";
import Box from '@mui/material/Box';
import ContentPage from './ContentPage';
import Typography from '@mui/material/Typography';


export default function About(){
    const error = useRouteError();
    console.error(error);

    const paragraphSx = { marginTop: '1em', fontSize: '1rem', color: '#111', lineHeight: '150%' }
    const titleText = "Oops!";
    const content = (
        <Box id="error-page">
            <Typography fontWeight="400" component="div" sx={paragraphSx}>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </Typography>
        </Box>);

    return <ContentPage titleText={titleText} content={content}/>
}
