import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { TAG_TO_TEXT } from '../Cms.js'

const Jewel = styled(Paper)(({ theme }) => ({
    padding: "2px 8px",
    fontSize: '0.8em',
    lineHeight: '1.75em',
    fontStyle: 'normal',
    fontWeight: 600,
    borderRadius: '4px',
    transition: 'none',
    boxShadow: 'none',
}));

const GreenJewel = styled(Jewel)(({ theme }) => ({
   color: '#FFFFFF',
   background: '#70C652',
   border: '1px solid #70C652',
}));

const BasicJewel = styled(Jewel)(({ theme }) => ({
   color: '#888888',
   border: '1px solid #CCCCCC',
}));


export default function Jewels(props) {
    let { tags } = props;
    const theme = useTheme();
    const matchesXl = useMediaQuery(theme.breakpoints.up('md'));

    const maxLabels = matchesXl ? 5 : 3;
    const labels = tags.map((tag, index) => {
        const text = TAG_TO_TEXT[tag]
        return (<BasicJewel key={index}>{text}</BasicJewel>);
    }).slice(0, maxLabels);

    return (
        <div>
          <Stack sx={{pb: '0.5em'}} direction="row" spacing={1}>
            {/*<GreenJewel>New</GreenJewel>
            <BasicJewel>No Experience</BasicJewel>
            <BasicJewel>Start Now</BasicJewel>*/}
            {labels}
          </Stack>
        </div>
      );
}