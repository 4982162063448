import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import JobSearch from './JobSearch';
import JobSearchItemsNarrow from './JobSearchItemsNarrow'
import useWindowDimensions from '../Hooks/useWindowDimensions.js'
import useImageLoader from '../Hooks/useImageLoader.js'
import { WorkIcon, LocationIcon } from '../Shared/CustomIcons'
import { JOB_EMPTY_AUTOCOMPLETE_TEXT, LOCATION_EMPTY_AUTOCOMPLETE_TEXT } from '../Cms.js'
import { LANDING_PAGE_TYPE } from '../Shared/SearchAutocompleteAsync';
import { jobValueState, locationValueState, getJobsUrl, suggestJobs, suggestLocations, getLocationCords } from '../GlobalState'
import { useGlobalState } from 'state-pool';
import { useCurrentPage } from '../Hooks/useCurrentPage'
import { searchClickEvent } from '../Shared/Events'


export default function Title(props){
    const theme = useTheme();
    const navigate = useNavigate();
    const page = useCurrentPage();

    // Jobs autocomplete vars
    const [jobValue, jobSetValue] = useGlobalState(jobValueState);
    const [jobInputValue, jobSetInputValue] = useState('');
    const [jobOptions, jobSetOptions] = useState([]);

    // Locations autocomplete vars
    const [locationValue, locationSetValue] = useGlobalState(locationValueState);
    const [locationInputValue, locationSetInputValue] = useState('');
    const [locationOptions, locationSetOptions] = useState([]);

    const { innerWidth, innerHeight } = useWindowDimensions();
    const isW800up = useMediaQuery(theme.breakpoints.up(800));

    /*
     Image logic:
     1. Show a dedicated image size for screen above/below 800px wide
     2. First load a low resolution image of the same size, and then replace it with the full resolution image
    */
    const desktopTinyUrl = "hpHeaderDesktop_tiny.jpg";
    const desktopUrl = 'hpHeaderDesktop.png';
    const mobileTinyUrl = 'hpHeaderMobile_720_tiny.jpg';
    const mobileUrl = 'hpHeaderMobile_720.png';
    const tinySrc = isW800up ? desktopTinyUrl : mobileTinyUrl;
    const src = isW800up ? desktopUrl : mobileUrl;
    const url = useImageLoader(tinySrc, src);

    const jobProps = {
        options: jobOptions,
        setOptions: jobSetOptions,
        noOptionsText: JOB_EMPTY_AUTOCOMPLETE_TEXT,
        fetchItems: suggestJobs,
        autoCompleteType: LANDING_PAGE_TYPE,
        color: "searchTextField",
        label: "What",
        icon: <WorkIcon color="primary"/>,
        value: jobValue,
        setValue: jobSetValue,
        inputValue: jobInputValue,
        setInputValue: jobSetInputValue,
    }

    const locationProps = {
        options: locationOptions,
        setOptions: locationSetOptions,
        noOptionsText: LOCATION_EMPTY_AUTOCOMPLETE_TEXT,
        fetchItems: suggestLocations,
        autoCompleteType: LANDING_PAGE_TYPE,
        color: "searchTextField",
        label: "Where",
        icon: <LocationIcon color="primary"/>,
        value: locationValue,
        setValue: locationSetValue,
        inputValue: locationInputValue,
        setInputValue: locationSetInputValue,
    }

    const getClickContext = () => {
        const jobQuery = jobInputValue || null;
        const url = getJobsUrl(jobQuery, locationValue);
        const location = getLocationCords(locationValue);
        return {jobQuery, url, location}
    }

    const searchOnClick = (event) => {
        const {jobQuery, url, location} = getClickContext();
        const metadata = JSON.stringify({new_url: url, info: "'new_url' is the new url this events navigates to"})
        const SEARCH_EVENT_TYPE = 'search_click';
        const eventData = {
            name: 'landing_search_click',
            type: 'search_click',
            tag: 'search_click',
            string_value: jobQuery,
            latitude: location.lat,
            longitude: location.long,
            metadata: metadata,
            page_type: page,
        }
        searchClickEvent(eventData)
        return navigate(url)
    }

    const buttonWidth = isW800up ? '160px' : '100%';
    const buttonMarginTop = isW800up ? '7px' : '1em';
    const buttonProps = {
        onClick: searchOnClick,
        variant: "contained",
        color: "appBarButton",
        size: "large",
        sx: {width: buttonWidth, fontSize: '16px', fontWeight: 700, marginTop: buttonMarginTop, marginBottom: '1em', textTransform: 'capitalize', lineHeight: 2, color: 'appBarButton.textColor', boxShadow: 'none', height: '52px', borderRadius: '12px'},
    }

    const searchItemsProps = {jobProps, locationProps, buttonProps};
    const SearchItemsMobile = isW800up ? null : <JobSearchItemsNarrow {...searchItemsProps} />
    const height = isW800up ? Math.max(721, innerHeight * 0.65) : 720;

    // Calculate the right image offset (for screen width sizes 800-1800 pixels)
    let rightOffset = 0;
    const imageOffset = 600
    const minWidthOffset = 800
    if (innerWidth >= minWidthOffset){
        const gap = innerWidth - minWidthOffset;
        rightOffset = Math.min(-10, imageOffset - (gap/2));
    }

    const res = (
        <Box>
            <Box sx={
                {
                    backgroundPosition: {xs: 'top center', w800: `top 0px right -${rightOffset}px`},
                    backgroundSize: 'cover',
                    backgroundImage: `url('${url}')`,
                    height: height,
                    backgroundRepeat: 'no-repeat'
                }
                }>
                <JobSearch {...searchItemsProps} searchItemsProps={searchItemsProps}/>

            </Box>
            {SearchItemsMobile}
        </Box>
    );
    return res;

}
