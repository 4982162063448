import { useTheme } from '@mui/material/styles';
import { alpha } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchAutocompleteAsync from '../Shared/SearchAutocompleteAsync';
import SearchIcon from '@mui/icons-material/Search';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { BUTTON_TEXT_WIDE_SCREEN } from '../Cms.js'


export default function JobSearchItemsWide(props){
    const {jobProps, locationProps, buttonProps} = props;
    const theme = useTheme();
    const mutualSx = { marginTop: '2em', marginLeft: 'auto', marginRight: 'auto', backgroundColor: alpha(theme.palette.primary.main, 0.9) }
    const wideSx = {  marginBottom: '1.5em', paddingTop: '2em', borderRadius: '12px', paddingBottom: '24px', width: '100%'}

    const inputSx = {width: 'calc(100% - 10px)', backgroundColor: '#FFF', border: '1px solid #AAAAAA', marginTop: '7px',  };

    return (
        <Box sx={{...mutualSx, ...wideSx}} >
           <Box sx={{ display: 'flex', marginLeft: '40px', marginRight: '40px', textAlign: 'start', justifyContent: 'center',}}>
               <Box sx={{ width: '100%'}}>
                   <Grid container spacing={0} justifyContent="center">
                       <Grid item xs={6} >
                           <SearchAutocompleteAsync {...jobProps} inputSx={inputSx} />
                       </Grid>
                       <Grid item xs={6} >
                          <SearchAutocompleteAsync {...locationProps} inputSx={inputSx} />
                      </Grid>
                   </Grid>
               </Box>
               <Box>
                    <FormLabel sx={{color: "#FFFFFF", fontWeight: 700, fontSize: '1.5rem', display: 'block', visibility: 'hidden' }}>Search</FormLabel>
                    <Button {...buttonProps} endIcon={<SearchIcon sx={{width:"26px", height: "26px"}} />} >{BUTTON_TEXT_WIDE_SCREEN}</Button>
               </Box>
           </Box>
        </Box>
    );
}
