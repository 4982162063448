import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PlaceIcon from '@mui/icons-material/PlaceOutlined';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Jewels from './Jewels';
import {CTA_MAIN_TEXT, ADDITIONAL_DETAILS} from '../Cms';
//import {POST_TYPE_JEWEL1, POST_TYPE_JEWEL2, POST_TYPE_JEWEL3, JEWEL1_TEXT, JEWEL2_TEXT, JEWEL3_TEXT} from '../Cms';


export default function JobPost(props) {
  const {title, location_raw, description, organization, adNumber, jobOnClick, url, id: adId, page, chartId, tags } = props;
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('lg'));
  // Remove commas from the end of string
  const location_str = location_raw.replace(/,\s*$/, "")

  // Per platform settings
  const cardSx = { margin: 'auto', maxWidth: '1200px' };
  const gridContainerSpacing = matchesSmall ? 0.5 : 2;
  const lineClamp = matchesSmall ? 2 : 3;
  const cardContentPaddingBottom = matchesSmall ? '2px' : '24px';
  const descriptionStyle = { overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', lineClamp: lineClamp, WebkitLineClamp: lineClamp, WebkitBoxOrient: 'vertical'};
  const ctaButtonSx = { fontSize: "large", fontWeight: 900, width: '100%', borderRadius: '7px' };

  const jobEventData ={
      name: "ad_click",
      type: "ad_click",
      tag: "ad_click",
      item_type: "ad",
      item_id: adId,
      page_type: page,
      chart_id: chartId,
      item_chart_position: adNumber,
      //"numeric_value": 0,
      //"metadata": "{test: 12}",
  }


  cardSx['width'] = matchesXl ? '70%' : '100%';
  const cardActionSx = matchesSmall ? {width: '90%'} : {} ;
  if (matchesSmall){
    cardSx['borderRadius'] = '10px';
    cardSx['paddingBottom'] = '0.5em';
  }

  const avatarGridItem = false ? (
    <Grid item xs={3}>
        <Box sx={{display: "flex", height: '100%'}}>
            <Avatar alt="Walmart" sx={{margin: 'auto'}} src="/homedepot_avatar_48.jpg" />
        </Box>
    </Grid>
  ) : null;


  return (
    <Card sx={cardSx}>

        <Grid container spacing={gridContainerSpacing}>
          <Grid item xl={8} lg={8} md={8} sm={7} xs={12}>
            <CardContent sx={{"&.MuiCardContent-root": {paddingBottom: cardContentPaddingBottom}}}>
                <Grid container>
                    {avatarGridItem}
                    <Grid item xs={9}>

                      {/* A JSX comment
                      <Button startIcon={<WorkOffIcon />} sx={{fontSize: 'small', marginBottom: '1em', textTransform: 'capitalize', lineHeight: 1.25}} variant="contained" size="xx-small">{TOP_BUTTON_TEXT}</Button>
                      */}
                      <Typography component="div" color={"primary"} fontWeight="700" fontSize= "16px">
                        {organization}
                      </Typography>
                      <Typography  component="div" sx={{fontWeight: "700",  fontSize: "18px", lineHeight: "130%" }}>
                        {title}
                      </Typography>
                      <Typography sx={{ mb: 1.5, mt: 0 }} color={"jobPostLocation.main"} fontSize="16px">
                        {<PlaceIcon sx={{verticalAlign: 'text-bottom'}}/>} {location_str}
                      </Typography>
                    </Grid>
                    {/* text ellipsis: https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css */}
                    <Grid item sm={3} xs={0} />
                    <Grid item sm={9} xs={12}>
                        <Jewels tags={tags} />
                        <Typography variant="body2" sx={descriptionStyle}>
                            {description}
                        </Typography>

                        {/* TODO: implement the ellipsis in JS code and add this text in the same line like in the Figma */}
                        <Typography variant="body2" color={"primary"} sx={{cursor: "pointer"}} fontWeight="400" onClick={() => jobOnClick('ADDITIONAL_DETAILS_BUTTON_CLICKED', jobEventData)} >
                            {ADDITIONAL_DETAILS}
                        </Typography>
                    </Grid>
                </Grid>

            </CardContent>
          </Grid>

          <Grid item justifyContent="center" alignItems="center" display="flex" xl={4} lg={4} md={4} sm={5} xs={12}>
              <CardActions sx={cardActionSx}>
                <Button onClick={() => jobOnClick('CTA_BUTTON_CLICKED', jobEventData, url)} variant="contained" size="large" sx={ctaButtonSx} >{CTA_MAIN_TEXT}</Button>
              </CardActions>
          </Grid>
        </Grid>
    </Card>
  );
}
