import React from 'react';
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import './App.css';
import { AppTheme } from './Shared/AppTheme';
import { UsePageViews } from './Hooks/usePageViews'
import router from './AppRouter'
import { initGA } from './Shared/Utils'


initGA();


export default function App() {

  return (
    <ThemeProvider theme={AppTheme}>
        <Box sx={{ bgcolor: 'appBackground.main' }} className="App">
            <RouterProvider router={router} >
                < UsePageViews />
            </ RouterProvider>
        </ Box>
    </ ThemeProvider>
  );
}
