import Dialog from "@mui/material/Dialog";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default function FullBottomScreenDialog(props: SimpleDialogProps) {
  const { open, closeDialog, contentElement, heightPercentage } = props;
  let { showCloseButton } = props;
  showCloseButton = showCloseButton || true;

  // Define the dialogs css based on the heightPercentage (percent from the screen from the bottom)
  const dialogSx = {
   "& .MuiDialog-container": {
     "& .MuiPaper-root": {
       width: "100%",
       maxWidth: "100%",
       borderRadius: 0,
       m: 0,
       height: `${heightPercentage}vh`,
       marginTop: `calc(100vh - ${heightPercentage}vh)`,
       display: 'flex',
       justifyContent: 'center',
     }
   }
 }

 const closeButton = showCloseButton ?
    (<IconButton
       aria-label="close"
       onClick={closeDialog}
       sx={{
         position: 'absolute',
         right: 8,
         top: 8,
         color: (theme) => theme.palette.grey[500],
       }}
     >
       <CloseIcon />
     </IconButton>)
    : null;

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      sx={dialogSx}
    >
        {closeButton}
        {contentElement}
    </Dialog>
  );
}