import { isDev } from './Shared/Utils'
import axios from 'axios';


const PROD_HOST = 'app.jobherenow.com';
const HOSTNAME = isDev() ? 'localhost' : PROD_HOST;
const PROTOCOL = isDev() ? 'http' : 'https';
const PORT_STR = isDev() ? ':3000' : '';
const GRAPHQL_URL = `${PROTOCOL}://${HOSTNAME}${PORT_STR}/graphql`;
const IP_GEOLOCATION_URL = "https://ipgeolocation.abstractapi.com/v1/?api_key=1fecae63c963472ebda0867f51683621";
const IP_GEOLOCATION_URL_IPINFO = 'https://ipinfo.io/json?token=aca803e727b451';
const JOB_POST_FIELDS = `
    {
        id
        title
        description
        url
        location
        location_raw
        domainId
        experience
        education
        job_type
        salary
        street_address
        postal_code
        city
        state
        country
        reference_number
        company_name
        logo
        tags
        url
    }
`;

const CHART_JOB_FIELDS = `
       {
        chartId
        items ${JOB_POST_FIELDS}
   	 }
`;


const isStr = (val) => (typeof val === 'string' || val instanceof String);
const isNonEmptyStr = (val) => ( isStr(val) && val.length > 0 );


export async function graphQlQuery(data){
    const req = axios({
      url: GRAPHQL_URL,
      method: 'post',
      data: data,
      withCredentials: true,
    });
    return req
}


export function getJobsQuery(lat, lang, searchTerm, page, label){
    const query = `
    {
        searchItems(latitude: ${lat}, longitude: ${lang}, searchTerm: "${searchTerm}", page: ${page}, tags: "${label}") ${JOB_POST_FIELDS}
    }
    `;
    return query;
}


export async function getJobPostsRes(query){
    const res = await axios({
      url: GRAPHQL_URL,
      method: 'post',
      data: {
        query: query,
        variables: null,
      },
      withCredentials: true,
    });
    return res.data
}

export async function getPosts(title, location, page=0, label){
  const query = getJobsQuery(location.lat, location.long, title, page, label)
  const data = await getJobPostsRes(query);
  const jobPosts = data.data.searchItems
  return jobPosts;
}

export async function suggestJobs(query){
    //console.log(`serverApi suggestJobs called with ${query}`);

    const gqlQuery = `{autoComplete(text: "${query}") }`;
    const req = axios({
      url: GRAPHQL_URL,
      method: 'post',
      data: {
        query: gqlQuery,
        variables: null,
      },
      withCredentials: true,
    });

    const res = await req;
    let titles = res.data.data.autoComplete;
    // Remove duplicates and return only 6 results. (TODO: add this to the API)
    titles = [...new Set(titles)].slice(0, 6);
    return titles;
}


export async function getChartPosts(adGroupId){
    let jobPosts = [];
    let chartId = null;
    if ( !isNonEmptyStr(adGroupId) ){
        return jobPosts
    }

    const query = `{ chart(adGroupId: "${adGroupId}") ${CHART_JOB_FIELDS} }`;
    const data = {
        query: query,
        variables: null,
    }

    try {
        const res = await graphQlQuery(data);
        jobPosts = res.data.data.chart.items;
        chartId = res.data.data.chart.chartId;
      } catch(err) {
        if (isDev()){
            const errorMsg = `Failed to fetch chart ${adGroupId}`;
            console.log(errorMsg, err); // TypeError: failed to fetch
        }
      }

    return {jobPosts, chartId};
}


export const submitEvent = async (eventData) => {
    const query = `mutation CreateEvent($eventData: Event) {
      createEvent(event: $eventData)
    }`;

    const data = {
        query: query,
        variables: {eventData: eventData},
    }

    try {
        await graphQlQuery(data);
        //console.log("Successfully registered event"); // TypeError: failed to fetch
    } catch(err) {
        if (isDev()){
            const errorMsg = `Failed to log event ${eventData}`;
            console.log(errorMsg, err); // TypeError: failed to fetch
        }

    }
};


let ipLocationRes = null;
export async function getIpLocation(){
    // Cache the result so save multiple calls to the API
    if (ipLocationRes !== null){
        //console.log("getIpLocation() loading from cache")
        return ipLocationRes;
    }
    //console.log("getIpLocation() calling the API");
    try {
        const res = await axios.get(IP_GEOLOCATION_URL);
        ipLocationRes = res.data;
        return ipLocationRes
    } catch(err) {
        if (ipLocationRes === null){
            if (isDev()){
                console.log("Failed call to IP_GEOLOCATION (probably because of rate limit), going to retry with IPINFO")
            }
            try {
                const res = await axios.get(IP_GEOLOCATION_URL_IPINFO);
                ipLocationRes = res.data;
                const location = ipLocationRes.loc.split(",");
                ipLocationRes['latitude'] = parseFloat(location[0]);
                ipLocationRes['longitude'] = parseFloat(location[1]);
                return ipLocationRes
            }
            catch(err2) {
               if (isDev()){
                   const errorMsg = "getIpLocation():: Failed to fetch ip location";
                   console.log(errorMsg, err2); // TypeError: failed to fetch
               }
            }

        } else {
            //console.log("Sequential call to getIpLocation() failed. Add bouncing functionality.", err)
        }

    }
}
