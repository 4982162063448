/*
Blog post: https://dev.to/yezyilomo/global-state-management-in-react-with-global-variables-and-hooks-state-management-doesn-t-have-to-be-so-hard-2n2c
Github package: https://github.com/yezyilomo/state-pool
*/
import { createGlobalstate } from 'state-pool';
import { getPosts as localGetPost, suggestJobs as localSuggestJobs, suggestLocations as localSuggestLocations  } from './Api'
import { getPosts as serverGetPosts, suggestJobs as serverSuggestJobs } from './ServerApi'
import { suggestLocations as mapBoxSuggestLocations } from './Mapbox'
import { round } from './Shared/Utils'


export const jobValueState = createGlobalstate(null);
export const locationValueState = createGlobalstate(null);


export const getLocationCords = (location) => {
    // TODO: rewrite this methods, seems like many unneeded if conditions. only needs to verify if 'location.center' exists
    let lat, long;
    if (location === null){
        long = undefined;
        lat = undefined;
    } else if (location.center !== undefined){
        long = round(location.center[0], 3);
        lat = round(location.center[1], 3);
    } else if (location.long === undefined || location.lat  === undefined ) {
        long = location.long;
        lat = location.lat;
    } else {
        long = undefined;
        lat = undefined;
    }
    return {long, lat}
}

export const getJobsUrl = (jobQuery, location, label=null) => {
    const cords = getLocationCords(location);

    let url;
    if (label === null){
        if ((jobQuery === null) && (location === null)){
            url = "/jobs";
        } else if (cords.long === undefined || cords.lat  === undefined ){
            url = `/jobs/${jobQuery}`;
        } else if (jobQuery === null) {
            url = `/jobs/long/${cords.long}/lat/${cords.lat}`;
        } else {
            url = `/jobs/${jobQuery}/long/${cords.long}/lat/${cords.lat}`;
        }
    } else {
        if ((jobQuery === null) && (location === null)){
            url = `jobs/label/${label}`;
        } else if (cords.long === undefined || cords.lat  === undefined ){
            url = `/jobs/${jobQuery}/label/${label}`;
        } else if (jobQuery === null) {
            url = `/jobs/label/${label}/long/${cords.long}/lat/${cords.lat}`;
        } else {
            url = `/jobs/${jobQuery}/label/${label}/long/${cords.long}/lat/${cords.lat}`;
        }
    }
    return url
}

// Application's Settings
/*
 useAPIMock:  temporary solution for using the backend or mock to retrieve posts
    false: uses ServerApi.js and retrieves data from the backend.
    true: uses Api.js and retrieves
*/
export const useAPIMock = createGlobalstate(false);
export const getPosts = useAPIMock.value ? localGetPost : serverGetPosts;
export const suggestJobs = useAPIMock.value ? localSuggestJobs : serverSuggestJobs;
export const suggestLocations = useAPIMock.value ? localSuggestLocations : mapBoxSuggestLocations;
