import ReactGA from 'react-ga4'


const PROD_TRACKING_ID = 'G-7VXS5FKWY8';
const DEV_TRACKING_ID = 'G-5XCLWM695P'


export function isDev(){
    return (window.location.hostname === 'localhost');
}


function getGATrackingId(){
    return isDev() ?  DEV_TRACKING_ID : PROD_TRACKING_ID;
}


export function initGA(){
    const TRACKING_ID = getGATrackingId();
    ReactGA.initialize(TRACKING_ID);
}


export const openInNewTab = (url) => {
    /*
    'noopener': prevents the new page from being able to access the window.opener property and ensures it runs in a separate process.
    Without this, the target page can potentially redirect your page to a malicious URL.
    https://mui.com/material-ui/react-link/#security
    */
    window.open(url, '_blank', 'noopener');
};


export const round = (num, digits=3) => {
    const multiplier = 10 ** digits
    return Math.round(num * multiplier) / multiplier
}
