import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import SearchAutocompleteAsync from './SearchAutocompleteAsync';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { SEARCH_DIALOG_TITLE } from '../Cms.js'


export default function SearchItemsDialogContent(props){
    const {jobProps, locationProps, onButtonClick } = props;
    const backgroundBoxSx = {marginTop: '2em', width: '100%',}
    const internalBoxWrapperSx = {margin: {w400: '0px auto',  xs:  '0px 20px'} };
    const internalBoxSx = {textAlign: 'center', justifyItems: "center",alignContent: 'space-between', display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto', mt: '8px'};
    const inputSx = {width: '100%', backgroundColor: '#FFF', mt: '8px' };

    const buttonProps = {
        onClick: onButtonClick,
        variant: "contained",
        color: "primary",
        size: "large",
        sx: {borderRadius: '12px', width: '100%', fontSize: 'large', marginTop: '1em', marginBottom: '1em', textTransform: 'capitalize', lineHeight: 2 ,color: 'primary.textColor'},
    }
    const labelProps = { labelFontWeight: 400, labelFontSize: '16px' };
    const titleSx = { fontWeight: 700, textAlign: 'center', lineHeight: '30px', fontSize: '20px' }

    return (
        <Box sx={backgroundBoxSx}>
            <Box sx={internalBoxWrapperSx}>
                <Box sx={internalBoxSx}>
                    <Grid container spacing={{xs: 3}} justifyContent="center">
                        <Grid item md={12} sm={12} xs={12} >
                            <Typography variant="h5" component="h2" sx={titleSx}>{SEARCH_DIALOG_TITLE}</Typography>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} >
                            <SearchAutocompleteAsync {...{...jobProps, ...labelProps}} inputSx={inputSx} />
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} >
                            <SearchAutocompleteAsync {...{...locationProps, ...labelProps}} inputSx={inputSx}  />
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>

                            <Button {...buttonProps} endIcon={<SearchIcon /> } >Find Jobs</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
