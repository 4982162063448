import { useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import BasePage from '../Shared/BasePage';
import JobPostList from './JobPostList';
import { getIpLocation } from '../ServerApi'
import { getPosts } from '../GlobalState.js'
import { useCurrentPage } from '../Hooks/useCurrentPage'
import { showMoreClickEvent } from '../Shared/Events'
import { round } from '../Shared/Utils'


export async function loader({ params }) {
  let {jobQuery, label, long, lat} = params;
  jobQuery = jobQuery || "";
  label = label || "";
  let locationQuery = "";
  if ((long === undefined) || (lat === undefined)){
      const ipLocation = await getIpLocation();
      lat = ipLocation.latitude;
      long = ipLocation.longitude;
      const region = ipLocation.region || "";
      const city = ipLocation.city || "";
      locationQuery = `${city}, ${region}`;
      //console.log(`Using lat/long from IP (${lat}, ${long})`);
  }
  else {
    //console.log(`Using lat/long from url (${lat}, ${long})`)
  }
  lat = round(lat, 3);
  long = round(long, 3);
  const location = {lat, long, locationQuery};
  const pageNum = 1;
  const initialJobPosts = await getPosts(jobQuery, location, pageNum, label);
  //console.log(`Done loading the new jobs: ${initialJobPosts}`)
  //console.log(initialJobPosts[0], initialJobPosts.length)
  return { initialJobPosts, jobQuery, location, label };
}


export default function SearchPage(props) {
    const loaderParams = useLoaderData();
    const { jobQuery, initialJobPosts, location, label } = loaderParams;
    const [jobPosts, setJobPosts] = useState(initialJobPosts);
    const [pageNum, setPageNum] = useState(1);
    const [labelQuery, setLabelQuery] = useState(label);
    const page = useCurrentPage();
    const { locationQuery } = location;

    useEffect(() => {
            //console.log(`reseting initialJobPosts: '${initialJobPosts}'`);
            //console.log(initialJobPosts[0], initialJobPosts.length)
            setJobPosts(initialJobPosts);
            setLabelQuery(label);
            setPageNum(1);
      }, [initialJobPosts, label]);


    const loadMoreClicked = async () => {
        const newPageNum = pageNum + 1
        const metadata = JSON.stringify({info: "'string_value' is the jobQuery, and 'numeric_value' is the new requested pagination number"})
        showMoreClickEvent({page_type: page, latitude: location.lat, longitude: location.long, string_value: jobQuery, numeric_value: newPageNum, metadata: metadata});
        //console.log(`loadMoreClicked() for new pageNum '${newPageNum}'`);
        const newJobPosts = await getPosts(jobQuery, location, newPageNum, label);
        //console.log(newJobPosts[0], [...jobPosts, ...newJobPosts].length)
        setPageNum(newPageNum);
        setJobPosts(prevJobPosts => [...prevJobPosts, ...newJobPosts]);
    }

    const jobListParams = { jobPosts, loadMoreClicked, page, pageNum};
    const SearchAppBarParams = { jobQuery, locationQuery, labelQuery };

    return <BasePage SearchAppBarParams={SearchAppBarParams} locationQuery={locationQuery} content={<JobPostList {...jobListParams} />}/>;
}
