// Mapbox API
import axios from 'axios';


const HOSTNAME = "api.mapbox.com";
const PROTOCOL = "https"
const BASE_URL = `${PROTOCOL}://${HOSTNAME}`
const ACCESS_TOKEN = "pk.eyJ1IjoicmJhaHVtaSIsImEiOiJjbGMwZWg0bmozNW8yM3ZxbnZkcDJndTRqIn0.bX6zMVqd-u9NA9HjU1QZ8A";
const GEOCODING_URL = `${BASE_URL}/geocoding/v5/mapbox.places`  // https://api.mapbox.com/geocoding/v5/mapbox.places


export async function suggestLocations(query){
    if (query === ''){
        return []
    }

    const url = `${GEOCODING_URL}/${query}.json`;
    const params = {
        access_token: ACCESS_TOKEN,
        proximity: "ip",  //proximity = `${long},${lat}`;
        types: 'country,region,place',
    };

    const req = await axios({
        url: url,
        method: 'get',
        params:params,
      });
    const res = await req;
    const locations = res.data.features;

    locations.forEach(location => location['label'] = location['place_name']);
    return locations
}