import React from 'react';
import SearchAutocomplete from '../Landing/SearchAutocomplete';
import AppBarAutocomplete from './AppBarAutocomplete';


export const LANDING_PAGE_TYPE = 'LANDING_PAGE';
export const APP_BAR_TYPE = 'APP_BAR';


export default function SearchAutocompleteAsync(props) {
    const {autoCompleteType, options, setOptions, value, inputValue, fetchItems} = props;
    let { defaultOptions } = props;
    defaultOptions = defaultOptions || [];

    // TODO: fix the use of useMemo - memoization currently doesn't work
    const memoFetchItems = React.useMemo(
        () =>
          (query) => {
                  fetchItems(query).then(results => {
                      let newOptions = defaultOptions;
                      if (results) {
                        const resultsSet = new Set(results)
                        newOptions = [...results, ...newOptions.filter((i) => !resultsSet.has(i))];
                      }
                      setOptions(newOptions);
                  });
              },
        [setOptions],
    );

    React.useEffect(() => {
        if (inputValue === '') {
          setOptions(value ? [value, ...defaultOptions] : defaultOptions);
          return undefined;
        }

        memoFetchItems(inputValue);
    }, [value, inputValue]);

    const newProps = {defaultOptions, ...props}
    const autoComplete = autoCompleteType === LANDING_PAGE_TYPE
        ? <SearchAutocomplete {...newProps}/>
        : <AppBarAutocomplete {...newProps}/>;

    return autoComplete

}