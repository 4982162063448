import SearchAutocompleteAsync from './SearchAutocompleteAsync';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import { BUTTON_TEXT_WIDE_SCREEN } from '../Cms.js'


export default function SearchItemsWideDevice(props){
    const {jobProps, locationProps, onClick } = props;
    const buttonProps = {
        onClick: onClick,
        variant: "contained",
        color: "appBarButton",
        size: "large",
        sx: {width: '160px', fontSize: '16px', fontWeight: 700, marginTop: '1em', marginBottom: '1em', marginLeft: '1em', lineHeight: 2, color: 'appBarButton.textColor', boxShadow: 'none', height: '52px', borderRadius: '12px'},
    }

    return (
        <Box sx={{width: '100%', display:"flex", flexDirection: "rowReverse", alignItems: "center"}}>
           <SearchAutocompleteAsync {...jobProps} />
           <SearchAutocompleteAsync {...locationProps} />
           <Button {...buttonProps} endIcon={<SearchIcon sx={{width:"26px", height: "26px"}} />} >{BUTTON_TEXT_WIDE_SCREEN}</Button>

        </Box>
    );
}


