import Box from '@mui/material/Box';
import Title from './Title';
//import Partners from './Partners';
import Footer from '../Shared/Footer';


export default function LandingPage(props){

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'landingBackground.main' }}>
            <Title />
            {/*<Partners />*/}
            <Footer />
        </ Box>
    );
}