import React, { useEffect } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


export default function Policy(key) {
    const paragraphSx = { marginTop: '1em', fontSize: '1rem', color: '#111', lineHeight: '150%' };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

  return (
    <Box>
        <Typography fontWeight="400" component="div" sx={paragraphSx}>
            <div
                name="termly-embed"
                data-id={key}
                data-type="iframe"
            ></div>
        </Typography>
    </Box>
)
}