import React from 'react';
import { createBrowserRouter, Navigate } from "react-router-dom";
import './App.css';
import SearchPage, {loader as SearchPageLoader } from './SearchPage/SearchPage';
import ChartPage, {loader as ChartPageLoader } from './SearchPage/ChartPage';
import LandingPage from './Landing/LandingPage';
import About from './StaticPages/About';
import Cookies from './StaticPages/Cookies';
import TermsAndConditions from './StaticPages/TermsAndConditions';
import PrivacyPolicy from './StaticPages/PrivacyPolicy';
import ErrorPage from './StaticPages/ErrorPage';


const router = createBrowserRouter([
  {
      path: "/",
      element: <LandingPage />,
      errorElement: <ErrorPage />,
      page: 'LandingPage',
  },
  {
      path: "jobs",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "jobs/label/:label",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "jobs/:jobQuery",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "jobs/:jobQuery/label/:label",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "jobs/long/:long/lat/:lat",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "jobs/label/:label/long/:long/lat/:lat",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "jobs/:jobQuery/long/:long/lat/:lat",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "jobs/:jobQuery/label/:label/long/:long/lat/:lat",
      element: <SearchPage />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'jobs',
  },
  {
      path: "ch/:adGroupId",
      element: <ChartPage />,
      loader: ChartPageLoader,
      errorElement: <ErrorPage />,
      page: 'charts',
  },
  {
      path: "about",
      element: <About />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'about'
  },
  {
      path: "cookies",
      element: <Cookies />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'cookies'
  },
  {
      path: "privacypolicy",
      element: <PrivacyPolicy />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'privacypolicy'
  },
  {
      path: "termsandconditions",
      element: <TermsAndConditions />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: 'termsandconditions'
  },
  {
      path: "*",
      element: <Navigate to="/" replace={true} />,
      loader: SearchPageLoader,
      errorElement: <ErrorPage />,
      page: '*'
  },
]);

export default router;
