import Box from '@mui/material/Box';
import SearchAppBar from './SearchAppBar';
import Footer from './Footer';


export default function BasePage(props) {
    const { content, SearchAppBarParams } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <SearchAppBar {...SearchAppBarParams}/>
            {content}
            <Footer />
        </ Box>
    );

}