import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import JobPost from './JobPost';
import ReactGA from 'react-ga4'
import { openInNewTab, isDev } from '../Shared/Utils'
import { jobClickEvent } from '../Shared/Events'


// Tmp url to use for opennning new tabs on click
const DEFAULT_INDEED_URL = "https://indeed.com/?r=us";


export default function JobPostList(params) {
  const {jobPosts, chartId, loadMoreClicked, page, pageNum} = params;
  let showLoadMore = params.showLoadMore !== undefined ? params.showLoadMore :  true;

  const loadMoreButton = showLoadMore ?
  <Box onClick={loadMoreClicked} sx={{ cursor: 'pointer', margin: 'auto', mt: {xs: '0.4em', md:'1em'} ,mb: {xs: '2em', md:'5em'}, pt: '8px', pb: '8px', border: '1px solid #666666', borderRadius: '7px', color: '#666666', fontWeight: 700, width: '90%', maxWidth: 300}}>
    Load more results
  </Box>
  : null;

  const jobOnClick = (name, jobEventData, url) => {
      jobClickEvent(name, jobEventData);
      const linkUrl = isDev() ? DEFAULT_INDEED_URL : url;
      openInNewTab(linkUrl);

      ReactGA.event({
          category: 'conversion',
          action: 'job_click',
          value: name === 'CTA_BUTTON_CLICKED' ? 1 : 2, // Optional value associated with the conversion
      });
  };

//loadMoreClicked
//onClick={()=> showMoreClickEvent({page: page})}
  return (
    <Box sx={{ width: '100%'}}>
        <List>
          {jobPosts.map((post, index) => {
              const adNumber = index + 1;
              return (
                <ListItem key={index}>
                  <JobPost page={page} adNumber={adNumber} jobOnClick={jobOnClick} chartId={chartId} {...post}/>
                </ListItem>
              );
            })}
        </List>

        {loadMoreButton}
    </Box>
  );
}
