import React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4'


export function UsePageViews() {
  let location = useLocation();
  // This console.log doesn't work
  //console.log("text 1");

  React.useEffect(() => {
        console.log(location.pathname);
        ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return null;
}