import { useState, useEffect } from 'react';

//function sleep (time) {
//  return new Promise((resolve) => setTimeout(resolve, time));
//}

/*
    This hook helps in look and feel of loading images.
    It first loads a low resolution image of the same size, and then replaces it with the full resolution image.
*/
export default function useImageLoader(tinySrc, src){
    const [imgSrc, setImgSrc] = useState(tinySrc);

    useEffect(() => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        //sleep(500).then(() => {
        //    setImgSrc(src);
        //});
        setImgSrc(src);
      };
    }, [src]);

    return imgSrc;
}