import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';


const NoStyleLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
  '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
  },
  // based on t1m0thy answer and its comments here: https://stackoverflow.com/questions/11078509/how-to-increase-the-clickable-area-of-a-a-tag-button
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '5px 0',
  margin: '-1em 0',
  //backgroundColor: 'yellow', // uncomment to see link's area
}));


export default NoStyleLink;