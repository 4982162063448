import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


export default function FooterGrid(props){
    const {items} = props;

    return (
        <Grid container spacing={2} justifyContent='center' sx={{textAlign: {md: "left", xs: "center"} }}>
          {items.map((name, index) => {
            const key = `footerItem${index}`;
            return (
              <Grid item key={key} sm={12} xs={12}>
                  <Box sx={{color: 'white', fontWeight: 700}}>
                    {name}
                  </Box>
              </Grid>
            );
          })}
        </Grid>
    );
}
